@import '@monsido/colors/dist/colors.scss';
@import './variables.scss';

$btn-border-radius: 3px;

.btn {
    &.btn-border-radius {
        border-radius: $btn-border-radius;
    }

    padding: 6px 12px;
    white-space: nowrap;
    --bs-btn-font-size: 12px;
}

.help-center-icon:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px $secondary-14;
}

.button-link-outline:focus {
    outline: none;
}

.button-link-outline:active:focus {
    outline: none;
}

.button-link-outline:focus-visible {
    outline: 2px solid $secondary-14;
    border-radius: $btn-border-radius;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn,
.btn-secondary:disabled,
.btn-secondary.disabled {
    cursor: not-allowed;
    opacity: .65;
    box-shadow: none;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    appearance: none;
    margin-top: -5px !important;
}

.close {
    float: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    filter: alpha(opacity=20);
}

a.btn.button-monsido-icon {
    border: 0;
    background: transparent;
    color: #474747;
    margin: 1px;
    display: inline-block;
    clear: both;

    &:hover {
        color: $main-1;
        margin: 0;
        border: 1px solid #ebebeb;
        border-radius: 4px;
    }
}

.btn-link-style,
.btn-link {
    text-decoration: none;
    color: $link-color;

    &:hover,
    &:focus {
        color: $link-hover-color !important;
    }

    &:focus-visible {
        text-decoration: $link-hover-decoration;
    }
}

.mon-button {
    width: 100%;
    --mon-button-width: 100%;
    --mon-button-padding-vertical: 9px;
    --mon-button-padding-horizontal: 12px;
    --mon-button-font-size: 12px;
    --mon-button-icon-font-size: 12px;
    --mon-button-text-padding-horizontal: 2px;
    white-space: nowrap;

    .accessibility-mode & {
        --mon-button-text-color: #{$color-dark-blue};
    }
}

.opt-button-main,
.policy-view-button-main {
    --bs-btn-font-weight: 400;
    --bs-btn-color: #ffffff;
    --bs-btn-bg: var(--opt-chat-bot-main-1);
    --bs-btn-border-color: var(--opt-chat-bot-main-1);
    --bs-btn-border-radius: 4px;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: var(--opt-chat-bot-main-2);
    --bs-btn-focus-shadow-rgb: var(--opt-chat-bot-main-4);
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: var(--opt-chat-bot-main-3);
    --bs-btn-active-border-color: var(--opt-chat-bot-main-3);
    --bs-btn-disabled-bg: var(--opt-chat-bot-main-5);
    --bs-btn-disabled-color: var(--opt-chat-bot-main-6);
    --bs-btn-disabled-border-color: var(--opt-chat-bot-main-6);
    border-width: 2px;
    border-style: solid;
    padding: 6px 8px;

    &--smaller {
        border-radius: 3px;
        border: none;
    }
}

.opt-button-main:active,
.opt-button-main:focus,
.opt-button-main:focus-visible {
    outline: none;
}

.opt-button-main:focus-visible {
    border-color: #ffffff;
    box-shadow: 0 0 0 2px var(--opt-chat-bot-main-4);
}

.opt-chip {
    --bs-btn-font-weight: 400;
    --bs-btn-color: rgba(43, 54, 59, 1);
    --bs-btn-bg: rgba(229, 233, 255, 1);
    --bs-btn-border-color: rgba(158, 172, 250, 1);
    --bs-btn-border-radius: 16px;

    --bs-btn-hover-color: rgba(24, 33, 89, 1);
    --bs-btn-hover-bg: rgba(191, 200, 253, 1);
    --bs-btn-hover-border-color: rgba(131, 148, 241, 1);

    --bs-btn-focus-bg: rgba(158, 172, 250, 1);

    --bs-btn-active-color: rgba(24, 33, 89, 1);
    --bs-btn-active-bg: rgba(158, 172, 250, 1);
    --bs-btn-active-border-color: rgba(131, 148, 241, 1);

    --bs-btn-disabled-bg: var(--opt-chat-bot-main-5);
    --bs-btn-disabled-color: var(--opt-chat-bot-main-6);
    --bs-btn-disabled-border-color: var(--opt-chat-bot-main-6);
    border-width: 1px;
    border-style: solid;
    padding: 6px 12px;
}

.opt-chip:not([aria-disabled="true"]):active:focus,
.opt-chip:not([aria-disabled="true"]):active,
.opt-chip:focus,
.opt-chip:focus-visible {
    outline-color: #ffffff;
    outline-width: 2px;
}

.opt-chip:focus-visible {
    border-color: #ffffff;
    box-shadow: 0 0 0 2px var(--opt-chat-bot-main-4);
}

.opt-chip[aria-disabled="true"] {
    cursor: not-allowed;

    &:not([aria-pressed="true"]) {
        --bs-btn-bg: var(--opt-chat-bot-main-5);
        --bs-btn-color: var(--opt-chat-bot-main-6);
        --bs-btn-border-color: var(--opt-chat-bot-main-6);

        --bs-btn-hover-bg: var(--opt-chat-bot-main-5);
        --bs-btn-hover-color: var(--opt-chat-bot-main-6);
        --bs-btn-hover-border-color: var(--opt-chat-bot-main-6);

        --bs-btn-focus-bg: var(--opt-chat-bot-main-5);

        --bs-btn-active-bg: var(--opt-chat-bot-main-5);
        --bs-btn-active-color: var(--opt-chat-bot-main-6);
        --bs-btn-active-border-color: var(--opt-chat-bot-main-6);
    }
}

.opt-chip[aria-pressed="true"] {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}